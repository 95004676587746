<template>

  <ion-list class="background-white" data-cy="tickets">
    <ion-list-header>
      <ion-label>{{ $t('choosetrainingticket') }}</ion-label>
    </ion-list-header>
    <ion-item v-for="ticket in tickets" :key="ticket.id">
      <ion-label>
        <ion-text color="white">
          <p>{{ ticket.name }}</p>
        </ion-text>

        <ion-text color="white">
          <p>{{ ticket.price | priceAmount }}</p>
        </ion-text>

        <ion-badge v-if="ticket.is_for_members && !(quantityAvailable(ticket) === 0)" slot="end" color="warning" class="mr-1">
          {{ $t('onlymembers') }}
        </ion-badge>

        <template v-if="ticket.is_required">
          <ion-badge v-if="!userHasPaidTicketForEvent(ticket)" slot="end" color="warning" class="mr-1">
            {{ $t('ticketisrequiredonceforevent') }}
          </ion-badge>
          <ion-badge v-else slot="end" color="success" class="mr-1">
            {{ $t('paidstatus') }}
          </ion-badge>
        </template>

        <ion-badge v-if="!requirementsMet(ticket)" slot="end" color="warning" class="mr-1">
          {{ $t('agerequirementsfailed') }}
        </ion-badge>

      </ion-label>

      <ion-badge v-if="quantityAvailable(ticket) === 0" slot="end" color="medium" class="mr-1">
        {{ $t('soldoutstatus') }}
      </ion-badge>

      <template v-else>
        <ion-checkbox
          v-if="ticket.max_tickets_per_ticket === 1"
          slot="end"
          color="moto"
          :checked="!!getSelectedTicketQuantity(ticket)"
          :disabled="ticketIsDisabled(ticket)"
          @ionChange="toggleSelectedTicket({ticket, checked: $event.detail.checked})"
        />

        <ion-select
          v-else
          :value="getSelectedTicketQuantity(ticket)"
          :disabled="ticketIsDisabled(ticket)"
          @ionChange="changeSelectedTicketQuantity({ticket, quantity: $event.target.value})"
        >
          <ion-select-option value="0">0</ion-select-option>
          <template v-for="value in ticket.max_tickets_per_ticket">
            <ion-select-option
              :key="value"
              :value="value"
              v-if="quantityAvailable(ticket) >= value">{{ value }}</ion-select-option>
          </template>
        </ion-select>
      </template>
    </ion-item>
  </ion-list>

</template>

<script>
  import { quantityAvailable } from "@/utils";
  import { mapActions, mapGetters, mapMutations } from "vuex";

  export default {
    name: 'ChooseTicketsToBuy',
    props: {
      tickets: {}
    },
    computed: {
      ...mapGetters([
        'userHasPaidTicketForEvent',
        'requirementsMet',
        'getSelectedTicketQuantity',
        'ticketIsDisabled',
      ]),
    },
    methods: {
      ...mapMutations([
        'changeSelectedTicketQuantity'
      ]),
      ...mapActions([
        'toggleSelectedTicket',
      ]),
      quantityAvailable
    }
  }
</script>