<template>
  <base-modal :title="$t('competitiontickets')" @close="close">
    <ion-content>

      <div v-if="stage == 1">
        <ion-list>
          <ion-list-header>
            <ion-text color="moto">{{ $t('ticketsforevents') }}</ion-text>
          </ion-list-header>
          <template v-if="competitionEvents.length" >
            <div v-for="competitionEvent in competitionEvents" :key="competitionEvent.id">
              <event-item :disabled="!competitionEvent.is_open || eventSoldOut(competitionEvent)" color="dark" :show-facility="true" :event="competitionEvent" />
              <ChooseProductsToBuy
                v-if="competitionEvent.is_open && !eventSoldOut(competitionEvent) && competitionEvent.products_for_sale && competitionEvent.products_for_sale.length"
                :products-for-sale="competitionEvent.products_for_sale" />
              <ChooseTicketsToBuy
                v-if="competitionEvent.is_open && !eventSoldOut(competitionEvent) && competitionEvent.tickets && competitionEvent.tickets.length"
                :tickets="competitionEvent.tickets" />
            </div>
          </template>
          <template v-else>
            <ion-item>
              <ion-text>{{ $t('competitionhasnoplannedevents')}}</ion-text>
            </ion-item>
          </template>

        </ion-list>
      </div>

      <div v-if="stage == 2">
        <ChooseProductsToBuy
          v-if="hasProductsForSale"
          :productsForSale="event.products_for_sale"
        />
        <template v-else>
          <ion-item>
            <ion-text>{{ $t('competitionhasnoproductsforsale')}}</ion-text>
          </ion-item>
        </template>
      </div>

      <div v-if="stage == 3">
        <RegistrationForm />
      </div>

      <div v-if="stage == 4">
        <TermsMon v-if="requireTermsMon" />
      </div>

    </ion-content>
    <ion-footer>
      <Error :error="error" />

      <template v-if="stage == 1">
        <ion-button
          color="moto"
          expand="full"
          @click="continueToProductsForSale"
          :disabled="!stage1NextButtonEnabled">{{ $t('next') }}</ion-button>
      </template>
      <template v-if="stage == 2">
        <ion-button
          color="secondary"
          expand="full"
          @click="stage = 1" >{{ $t('back') }}</ion-button>
        <ion-button
          color="moto"
          expand="full"
          @click="continueToRegistrationForm"
          :disabled="!stage2NextButtonEnabled">{{ $t('next') }}</ion-button>
      </template>
      <template v-if="stage == 3">
        <ion-button
          color="secondary"
          expand="full"
          @click="backToProductsForSale" >{{ $t('back') }}</ion-button>
        <ion-button
          v-if="requireTermsMon"
          color="moto"
          expand="full"
          @click="continueToDisclaimer"
          :disabled="!stage3NextButtonEnabled">{{ $t('next') }}</ion-button>
        <ion-button
          v-else
          color="moto"
          expand="full"
          @click="checkoutWithStripe"
          :disabled="checkoutButtonDisabled">{{ $t('tocheckout') }}</ion-button>
      </template>
      <template v-if="stage == 4">
        <ion-button
          color="secondary"
          expand="full"
          @click="stage = 3" >{{ $t('back') }}</ion-button>
        <ion-button
          color="moto"
          expand="full"
          @click="checkoutWithStripe"
          :disabled="checkoutButtonDisabled">{{ $t('tocheckout') }}</ion-button>
      </template>
    </ion-footer>
  </base-modal>
</template>

<script>
  import { get } from "lodash";
  import { fieldsFormPresentation } from "@/components/inputs/field_mapping";
  import { logoutAndRedirect } from '@/utils/logoutAndRedirect';
  import { hasAuthError } from '@/utils/hasAuthError';
  import BaseModal from './BaseModal.vue';
  import { Capacitor } from '@capacitor/core';
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { noProductsAvailable, noTicketsAvailable, priceAmount, stripeFee } from "@/utils";
  import { APP_DEFAULTS, STRIPE_API_KEY } from "@/configs";
  import StripeCheckout from "@/components/modals/StripeCheckout";
  import { quantityAvailable, stockAvailable, eventSoldOut } from "@/utils/events";
  import { RegistrationForm } from "@/components/forms";
  import ChooseProductsToBuy from "@/components/ChooseProductsToBuy";
  import TermsMon from "@/components/TermsMon";
  import { GET_COMPETITION_EVENTS } from "@/graphql/queries";
  import EventItem from "@/components/items/EventItem";
  import ChooseTicketsToBuy from "@/components/ChooseTicketsToBuy";
  import moment from "moment-timezone";
  import { Browser } from '@capacitor/browser';
  
  export default {
    components: {
      ChooseTicketsToBuy,
      TermsMon,
      ChooseProductsToBuy,
      RegistrationForm,
      BaseModal,
      EventItem,
    },
    props: ['closeMe', 'form', 'event', 'setInstance'],
    data() {
      return {
        stage: 1,
        updateTrigger: 0,
        fields: fieldsFormPresentation,
        total: 0,
        totalTickets: 0,
        loading: false,
        competitionEvents: [],
      }
    },
    computed: {
      ...mapGetters({
        error: 'getBuyTicketsError',
        user: CURRENT_USER_GETTER,
        form_values: 'getRegistrationFormValues',
        additionalFields: 'getRegistrationAdditionalFields',
        selectedTickets: 'getSelectedTickets',
        selectedProducts: 'getSelectedProducts',
        paidOrders: 'getPaidOrders',
        hasProductsForSale: 'hasProductsForSale',
        productsForSale: 'getProductsForSale',
        requireTermsMon: 'requireTermsMon',
        requiredTicketsAreNotSelected: 'requiredTicketsAreNotSelected',
        requiredProductsAreNotSelected: 'requiredProductsAreNotSelected',
        anySelectedTicketIsSoldOut: 'anySelectedTicketIsSoldOut',
        anySelectedProductIsSoldOut: 'anySelectedProductIsSoldOut',
        totalPrice: 'totalPrice',
        requiredTermsFilledIn: 'requiredTermsFilledIn',
        getEventIdsWithProductsOrTicketsSelected: 'getEventIdsWithProductsOrTicketsSelected',
        lineItems: 'getLineItems',
      }),
      stage1NextButtonEnabled() {
        return !(this.requiredTicketsAreNotSelected(this.getEventIdsWithProductsOrTicketsSelected) || this.requiredProductsAreNotSelected(this.getEventIdsWithProductsOrTicketsSelected))
      },
      stage2NextButtonEnabled() {
        return !this.requiredProductsAreNotSelected([this.event.id]);
      },
      stage3NextButtonEnabled() {
        return true;
      },
      stage4NextButtonEnabled() {
        return this.requiredTermsFilledIn;
      },
      checkoutButtonDisabled() {
        return (
          this.requiredTicketsAreNotSelected(this.getEventIdsWithProductsOrTicketsSelected) || this.requiredProductsAreNotSelected(this.getEventIdsWithProductsOrTicketsSelected)
        ) || (
          this.loading || !this.requiredTermsFilledIn
        ) || (
          this.anySelectedTicketIsSoldOut || this.anySelectedProductIsSoldOut
        ) || (
          !this.selectedTickets.length && !this.selectedProducts.length
        )
      },
    },
    created() {
      /**
       * Inform our parent that we have been created, so it can destroy us.
       */
      if (this.setInstance) {
        this.setInstance(this);
      }

      this.clearBuyTickets();
    },
    mounted() {
      this.addProductsForSale(this.event.products_for_sale);
      this.fetchPaidOrdersForEvent(this.event.id);
      this.setRegistrationForm(this.form);
      this.setRegistrationPresentation(this.fields);
      this.hydrateRegistrationForm({clear: true});
      this.fetchCompetitionEvents(this.event.id);
    },
    watch: {
      updateTrigger() {
        /**
         *  Our disabled key relies on form_values, which add/removes properties on-the-fly, which is not supported by vue-watchers
         *  This is a cheap way to update the UI
         */
        this.$nextTick( () => {
          this.$forceUpdate();
        })
      }
    },
    methods: {
      ...mapMutations([
        'clearBuyTicketsError',
        'setBuyTicketsError',
        'setRegistrationForm',
        'setRegistrationPresentation',
        'addProductsForSale',
        'addTicketsForSale',
        'mergeRegistrationForm',
      ]),
      ...mapActions([
        'hydrateRegistrationForm',
        'clearBuyTickets',
        'checkoutCompetition',
        'updatePaymentMethodForOrder',
        'fetchPaidOrdersForEvent',
        'fetchPaidOrdersForEvents',
        'toggleSelectedTicket',
        'validateAdditionalFields',
      ]),
      priceAmount,
      stripeFee,
      quantityAvailable,
      stockAvailable,

      eventSoldOut(event) {
        // Event reached capacity
        if(eventSoldOut(event) || (noTicketsAvailable(event) && noProductsAvailable(event))) {
          return true
        }

        return false;
      },

      continueToProductsForSale() {
        if (!this.event?.products_for_sale?.length) {
          return this.continueToRegistrationForm();
        }
        this.stage = 2;
      },

      backToProductsForSale() {
        if (!this.event?.products_for_sale?.length) {
          this.stage = 1;
          return;
        }
        this.stage = 2;
      },

      continueToRegistrationForm() {
        // Set the initial registration form
        this.setRegistrationForm(this.form);
        // Now add the rest of the registration forms on top of it
        const selectedEvents = this.competitionEvents.filter( (event) => this.getEventIdsWithProductsOrTicketsSelected.includes(event.id));
        selectedEvents.forEach( (event) => {
          const eventRegistrationForm = event.forms.slice().pop();
          this.mergeRegistrationForm(eventRegistrationForm);
        })
        this.hydrateRegistrationForm({clear: false, overwrite: false})
        this.stage = 3;
      },

      continueToDisclaimer() {
        this.clearBuyTicketsError();

        this.validateAdditionalFields();

        // Additional-fields have errors
        if (this.error) {
          return;
        }

        this.stage = 4;
      },

      async openStripeCheckoutModal() {
          const p = new Promise( async (resolve) => {
            const propsData = {
              closeMe: null,
              line_items: this.lineItems,
              user: this.user,
              confirm: (paymentMethod) => {
                modal.dismiss();
                resolve(paymentMethod);
              }
            };

            const modal = await this.$ionic.modalController
              .create({
                component: StripeCheckout,
                componentProps: {
                  parent: this,
                  propsData
                },
              });

            propsData.closeMe = () => {
              modal.dismiss();
              resolve(false);
            };

            return modal.present();
          });
          return p;
      },
      async checkoutWithStripe() {
        this.loading = true;
        this.clearBuyTicketsError();

        this.validateAdditionalFields();

        // Additional-fields have errors
        if (this.error) {
          this.loading = false;
          return;
        }

        try {
          const data = await this.checkoutCompetition({competition_id: this.event.id});

          // If the user left the browser open; this can happen..
          if(get(data, 'errors[0].extensions.category') === 'authentication') {
            this.close()

            await logoutAndRedirect();
            return;
          }

          let result = data.data.checkoutCompetition;

          if (result.__typename === 'Order') {
            this.close();

            await this.$router.push({name: this.$routeNames.THANKS});
            return;
          }

          if(result.stripe_account) {

            // In our new api, we will update the order and not the session_id
            if (!result.session_id) {
              const paymentMethod = await this.openStripeCheckoutModal();

              if (!paymentMethod) {
                return;
              } else {
                result = await this.updatePaymentMethodForOrder({order_id: result.order_id, payment_method: paymentMethod});
              }
            }

            return this.openStripeBrowserSession(result.session_id);
          }
        } catch( error ) {

          if(hasAuthError(error)) {
            this.close();
            await logoutAndRedirect();
          }

          this.setBuyTicketsError(error);
        } finally {
          this.loading = false;
        }

      },
      async openStripeBrowserSession(sessionId) {

        if(Capacitor.isNativePlatform()) {
          await Browser.open({ url: `${process.env.VUE_APP_API_URL}/redirect-to-stripe?session_id=${sessionId}` });
          return;
        }

        const stripe = window.Stripe(STRIPE_API_KEY);

        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.

          // eslint-disable-next-line no-console
          console.log(result.error.message)
        });
      },
      close() {
        this.closeMe();
      },

      async fetchCompetitionEvents() {
        const response = await this.$apollo.query({
          query: GET_COMPETITION_EVENTS,
          fetchPolicy: 'network-only',
          variables: {
            competition_event_id: this.event.id,
          }
        });

        const now = moment.tz(APP_DEFAULTS.DEFAULT_TIMEZONE);

        const competitionEvents = response.data.getCompetitionEvents.filter(event => {
          return moment.tz(event.ends_at, APP_DEFAULTS.DEFAULT_TIMEZONE).isAfter(now);
        });

        this.setCompetitionEvents(competitionEvents);
      },

      setCompetitionEvents(competitionEvents) {
        this.competitionEvents = competitionEvents;

        if (!this.competitionEvents.length) {
          return;
        }

        this.fetchPaidOrdersForEvents(this.competitionEvents.map(event => event.id));

        this.competitionEvents.forEach((event) => {
          this.addTicketsForSale(event.tickets)
          this.addProductsForSale(event.products_for_sale)
        })
      },

    },

  }
</script>
