<template>
  <base-modal :title="$t('torefund')" @close="close">
    <ion-content>
      <div class="ion-padding">
        <ion-text v-if="canRefund">
          <template v-if="isPartiallyRefundable">
            {{ $t('selecttheitemsyouwanttorefund') }}
          </template>
          <template v-else>
            {{ $t('areyousureyouwanttorefund') }}
          </template>
        </ion-text>
        <ion-text v-else>
          {{ $t('refundinfo') }}
        </ion-text>
      </div>

      <ion-list class="mt-4">
        <template v-for="(eventGroup, idx) in eventGroups">
          <div :key="idx">
            <ion-item-divider color="dark">
              <ion-text>{{ eventGroup.event.title }}</ion-text>
              <span v-if="isPartiallyRefundable" slot="end">
                <ion-button
                  v-if="hasGroupSelection(idx)"
                  color="moto"
                  fill="clear"
                  size="small"
                  @click="deselectAllOrderablesFromGroup(idx)">{{ $t('deselectall') }}</ion-button>
                <ion-button
                  v-else
                  color="moto"
                  fill="clear"
                  size="small"
                  @click="selectAllOrderablesFromGroup(idx)">{{ $t('selectall') }}</ion-button>
                </span>
            </ion-item-divider>

            <div v-for="(itemGroup, ticketIdx) in eventGroup.groupedTickets" :key="ticketIdx">
              <ion-item v-for="(item, idx) in itemGroup.items" :key="idx" @click="toggle(item.pivot.id)">
                <ion-checkbox
                  slot="start"
                  color="moto"
                  :checked="isSelected(item.pivot.id)"
                  v-if="isPartiallyRefundable" />
                <ion-label>{{ itemGroup.name }}</ion-label>
              </ion-item>
            </div>
            <div v-for="(itemGroup, ticketIdx) in eventGroup.groupedProducts" :key="ticketIdx">
              <ion-item v-for="(item, idx) in itemGroup.items" :key="idx" @click="toggle(item.pivot.id)">
                <ion-checkbox
                  slot="start"
                  color="moto"
                  :checked="isSelected(item.pivot.id)"
                  v-if="isPartiallyRefundable" />
                <ion-label>{{ itemGroup.name }}</ion-label>
              </ion-item>
            </div>
          </div>
        </template>
      </ion-list>
    </ion-content>

    <ion-footer>
      <template v-if="canRefund">
        <ion-button expand="full" color="moto" @click="confirm" :disabled="refundIsDisabled">{{ $t('torefund') }}
        </ion-button>
      </template>
      <template v-else>
        <ion-button expand="full" color="secondary" @click="close">{{ $t('close') }}</ion-button>
      </template>
    </ion-footer>
  </base-modal>
</template>

<script>
  import BaseModal from './BaseModal.vue'
  import { groupOrderablesByEvent } from "@/utils";

  export default {
    components: {
      BaseModal,
    },
    props: {
      order: {
        type: Object
      },
      onConfirm: {
        type: Function,
      },
      canRefund: {
        type: Boolean,
      }
    },
    data() {
      return {
        selectedOrderableIds: [],
      }
    },
    computed: {
      isPartiallyRefundable() {
        return this.order?.is_partially_refundable
      },
      eventGroups() {
        return groupOrderablesByEvent([this.order], {removeRefunded: true}).filter(eventGroup => !eventGroup.is_refunded);
      },
      /**
       * Disables the refund button
       *
       * @returns {boolean}
       */
      refundIsDisabled() {
        if (!this.isPartiallyRefundable) {
          return false;
        }
        return !this.selectedOrderableIds.length;
      }
    },
    methods: {
      toggle(id) {
        if (this.isSelected(id)) {
          this.deselectOrderableId(id);
        } else {
          this.selectOrderableId(id);
        }
      },
      isSelected(id) {
        return this.selectedOrderableIds.includes(id);
      },
      selectOrderableId(id) {
        if (!this.selectedOrderableIds.includes(id)) {
          this.selectedOrderableIds.push(id);
        }
      },
      deselectOrderableId(id) {
        const idx = this.selectedOrderableIds.indexOf(id);

        if (idx === -1) {
          return;
        }

        this.selectedOrderableIds.splice(idx, 1);
      },
      selectOrderableIds(selected) {
        selected.forEach(id => this.selectOrderableId(id));
      },
      deselectOrderableIds(deselected) {
        this.selectedOrderableIds = this.selectedOrderableIds.filter((id) => {
          return !deselected.includes(id);
        })
      },
      /**
       * Are any of the orderables in this group selected?
       *
       * @param idx
       * @returns {boolean}
       */
      hasGroupSelection(idx) {
        const someTicketsSelected = Object.values(this.eventGroups[idx].groupedTickets)
          .some((tickets) => {
            return tickets.items.some(item => this.selectedOrderableIds.includes(item.pivot.id))
          });
        const someProductsSelected = Object.values(this.eventGroups[idx].groupedProducts)
          .some((products) => {
            return products.items.some(item => this.selectedOrderableIds.includes(item.pivot.id))
          });

        return someTicketsSelected || someProductsSelected;
      },
      /**
       * Select all orderables from this group
       * @param idx
       */
      selectAllOrderablesFromGroup(idx) {
        const selected = [];

        Object
          .values(this.eventGroups[idx].groupedTickets)
          .forEach(tickets => tickets.items.forEach((item) => selected.push(item.pivot.id)))

        Object
          .values(this.eventGroups[idx].groupedProducts)
          .forEach(products => products.items.forEach((item) => selected.push(item.pivot.id)))

        this.selectOrderableIds(selected);
      },
      /**
       * Deselect all orderables from this group
       * @param idx
       */
      deselectAllOrderablesFromGroup(idx) {
        const deselected = [];

        Object.values(this.eventGroups[idx].groupedTickets)
          .forEach(tickets => tickets.items.forEach((item) => deselected.push(item.pivot.id)))

        Object.values(this.eventGroups[idx].groupedProducts)
          .forEach(products => products.items.forEach((item) => deselected.push(item.pivot.id)))

        this.deselectOrderableIds(deselected);
      },
      close() {
        this.$ionic.modalController.dismiss()
      },
      confirm() {
        if (this.isPartiallyRefundable) {
          this.onConfirm({orderable_ids: this.selectedOrderableIds})
        } else {
          this.onConfirm(true);
        }

        this.close();
      }
    }
  }
</script>