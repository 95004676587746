<template>
  <ion-list ref="slidingList" class="registration-list mt-4">
    <ion-list-header>
      <ion-label>{{ $t('registrations') }}</ion-label>
    </ion-list-header>

    <div v-if="!$apollo.loading">
      <ion-item-sliding v-for="attendee in deduplicatedAttendees" :key="attendee.id">
        <ion-item button @click="openDetailsView(attendee)">
          <ion-label>
            <h2>{{ attendee.user.first_name }} {{ attendee.user.last_name }}</h2>
            <h3>{{ attendee.user.email }}</h3>
            <ion-text v-if="attendee.order" color="moto" class="text-sm">
              {{ $t('totalprice', {price: stripeAmount(attendee.order.total_price)}) }}
            </ion-text>
            <ion-text class="text-sm">
              <div>{{ $t('viewmoredetails') }} &gt;</div>
            </ion-text>
          </ion-label>
        </ion-item>
        <ion-item-options>
          <ion-item-option
            :color="canRefundOrder(attendee.order) ? 'danger' : 'secondary'"
            @click="refundRider(attendee)">
            <ion-icon name="trash" />
            {{ $t('torefund') }}
          </ion-item-option>

          <ion-item-option color="medium" @click="mailRider(attendee)">
            <ion-icon name="mail" />
            {{ $t('toemail') }}
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </div>

    <SkeletonList v-if="$apollo.loading" />

    <div v-if="!$apollo.loading && (!attendees || !attendees.length)">
      <ion-item>
        <ion-label text-wrap>{{ $t('noeventregistrationsyet') }}</ion-label>
      </ion-item>
    </div>

  </ion-list>
</template>

<script>
  import { GET_EVENT_ATTENDEES } from "../graphql/queries";
  import TrainingRegistrationDetailsModal from "@/components/modals/TrainingRegistrationDetailsModal";
  import { REFUND_RIDER } from '../graphql/mutations';
  import { StripeStatus } from "@/graphql/enums";
  import moment from 'moment-timezone';
  import { STRIPE_REFUND_LIMIT_DAYS } from "@/configs";
  import RefundOrderConfirmationModal from "@/components/modals/RefundOrderConfirmationModal";
  import { captureException, stripeAmount, showLoading } from "@/utils";

  export default {
    props: ['event'],
    apollo: {
      attendees: {
        query: GET_EVENT_ATTENDEES,
        fetchPolicy: 'network-only',
        variables() {
          return {
            expand_competitions: true,
            event_id: this.event.id
          }
        },
        update: data => data.getEventRegistrations
      }
    },
    computed: {
      // This returns only a single attendee for each order
      deduplicatedAttendees() {
        if (!this.attendees?.length) {
          return [];
        }
        const orderIds = [];

        return this.attendees.filter((attendee) => {
          if (orderIds.includes(attendee.order.id)) {
            return false;
          }

          orderIds.push(attendee.order.id);

          return true;
        })
      }
    },
    methods: {
      showLoading,
      stripeAmount,
      canRefundOrder(order) {
        // Can not refund if not ordered
        if (!order) {
          return false;
        }

        // Can not refund if not paid
        if (![StripeStatus.paid, StripeStatus.partially_refunded].includes(order.stripe_status)) {
          return false;
        }

        // Can not refund if over stripe refund days
        return Math.abs(moment.utc(order.created_at).diff(moment(), 'days')) < STRIPE_REFUND_LIMIT_DAYS;
      },
      mailRider(attendee) {
        window.location.href = `mailto:${attendee.user.email}`
      },

      async confirmRefund(attendee) {
        return new Promise(async (resolve) => {

          const modal = await this.$ionic.modalController
            .create({
              component: RefundOrderConfirmationModal,
              componentProps: {
                parent: this,
                propsData: {
                  order: attendee.order,
                  canRefund: this.canRefundOrder(attendee.order),
                  onConfirm: (value) => resolve(value ?? true)
                },
              },
            });

          modal.onDidDismiss().then(() => resolve(false));

          await modal.present();
        });
      },
      async refundRider(attendee) {
        await this.$refs.slidingList.closeSlidingItems();
        const confirmed = await this.confirmRefund(attendee);

        if (!confirmed) {
          return;
        }

        const variables = {
          attendee_id: attendee.id
        }

        // Only these orderables
        if (confirmed.orderable_ids) {
          variables.orderable_ids = confirmed.orderable_ids;
        }

        const p = await this.showLoading(this.$t('refundingpleasewait'));

        try {
          await this.$apollo.mutate({
            mutation: REFUND_RIDER,
            variables
          });

          const toast = await this.$ionic.toastController.create({
            color: 'success',
            message: this.$t('refundedsuccessfully'),
            showCloseButton: true,
          });

          await toast.present();
          this.$apollo.queries.attendees.refetch()
        } catch (error) {
          const toast = await this.$ionic.toastController.create({
            color: 'danger',
            message: error?.message,
            showCloseButton: true,
          });

          captureException(error)

          await toast.present();
        } finally {
          p.dismiss();
        }

      },
      openDetailsView(attendee) {
        return this.$ionic.modalController
          .create({
            component: TrainingRegistrationDetailsModal,
            componentProps: {
              parent: this,
              propsData: {
                attendee,
                additionalValues: attendee.submission.additional_values,
                submittedValues: attendee.submission.submitted_values,
              },
            },
          })
          .then(m => m.present())
      }
    }

  }
</script>
