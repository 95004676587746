<template>
  <ion-item>
    <ion-label position="stacked">{{ label }}</ion-label>
    <ion-select
      multiple
      :placeholder="placeholder || $t('pickanoption')"
      @ionChange="onChange($event.target.value)"
    >
      <ion-select-option
        v-for="(text, idx) in options"
        :key="idx"
        :value="text"
        :selected="isSelected(idx)"
      >{{ text }}</ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script>
  export default {
    props: {
      label: {},
      placeholder: {},
      value: {},
      options: {},
      required: {}
    },
    methods: {
      onChange(selected) {
        this.$emit('inputChanged', selected);
      },
      isSelected(idx) {
        return this.value.includes(this.options[idx]);
      },
    }
  }
</script>