<template>
  <ion-page class="ion-page">
    <ContentContainer v-if="event">
      <Refresher @refresh="doRefresh($event)" />

      <PageItem v-if="!event.organizer.stripe && userIsAdmin" class="no-margin-top">
        {{ $t('linktostripebuy', { facility: event.organizer.name }) }}

        <div>
          <ion-button
            size="small"
            :href="`https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_ID}&stripe_user[business_type]=company&state=event/${$route.params.id}`"
          >
            {{ $t('connecttostripe') }}
          </ion-button>
        </div>
      </PageItem>

      <div
        :style="{ backgroundImage: `url('${$apiStorageUrl}/${ event.image }` }"
        style="height: 30vh; background-size: cover; background-position: center;"
      />

      <PageItem class="no-margin-top pb-0 no-padding-x">
        <div>
          <div class="px-4">
            <ion-text color="moto">
              <h3>{{ event.held_at|formatDateTime }} - {{ event.ends_at|formatDateTime }}</h3>
            </ion-text>
            <ion-text>
              <h1 class="text-2xl font-light">
                {{ event.title }}
              </h1>
            </ion-text>

            <div class="mt-3">

              <ion-badge color="dark" class="mr-2" v-if="event.event_category.name === 'COMPETITION'">
                <ion-text color="moto">{{ $t('competitionstatus') }}</ion-text>
              </ion-badge>

              <ion-badge color="danger" class="mr-2" v-if="event.cancelled_at">
                {{ $t('cancelledstatus') }}
              </ion-badge>

              <div></div>

              <ion-text>
                {{ event.organizer.name }}
              </ion-text>
              <ion-text color="moto" v-if="event.host_facility">
                ({{ $t('hostedby') }}: {{ event.host_facility.name }})
              </ion-text>
            </div>

            <div class="mb-2">
              <ion-text>
                {{ event.location.name }}
              </ion-text>
            </div>

            <div class="flex">
              <div v-if="(buttonType === 'pay-cash') && (event.event_category.name === 'TRAINING')" class="flex-1">
                <ion-button size="small" expand="full" disabled color="secondary">{{ $t('paycashatevent') }}</ion-button>
              </div>
              <ion-button
                v-else-if="['buy', 'pay-cash'].includes(buttonType)"
                size="small"
                data-cy="ticket-button"
                color="moto"
                class="flex-1"
                @click="openTicketModal()"
                key="buy-training-ticket"
              >
                <ion-icon src="/icons/ticket.svg" color="primary" class="mr-2" />
                <span v-if="event.event_category.name == 'COMPETITION'">{{ $t('buyticket') }}</span>
                <span v-else>{{ $t('buytrainingticket') }}</span>
              </ion-button>
              <ion-button v-if="buttonType === 'passed-event'" size="small" class="flex-1" :disabled="true" color="medium">
                <ion-icon src="/icons/ticket.svg" color="white" class="mr-2" />
                <span>{{ $t('eventalreadygiven') }}</span>
              </ion-button>
              <ion-button v-if="buttonType === 'cancelled'" size="small" class="flex-1" :disabled="true" color="danger">
                <ion-icon src="/icons/ticket.svg" color="white" class="mr-2" />
                <span>{{ $t('cancelledstatus') }}</span>
              </ion-button>
              <ion-button v-if="buttonType === 'sold-out'" size="small" class="flex-1" :disabled="true" color="medium">
                <ion-icon src="/icons/ticket.svg" color="white" class="mr-2" />
                <span>{{ $t('soldout') }}</span>
              </ion-button>
              <ion-button v-if="buttonType === 'payment-not-available'" size="small" class="flex-1" :disabled="true" color="medium">
                <ion-icon src="/icons/ticket.svg" color="white" class="mr-2" />
                <span>{{ $t('paymentnotavailable') }}</span>
              </ion-button>
              <ion-button
                v-if="buttonType === 'sale-not-started'"
                size="small"
                class="flex-1 overflow-hidden"
                :disabled="true"
                color="medium"
                key="sale-not-started">
                <ion-icon src="/icons/ticket.svg" color="white" class="mr-2" />
                <span>{{ $t('salestartsin', {duration: humanDiff(event.opens_at)}) }}</span>
              </ion-button>
              <ion-button v-if="userIsAdmin" size="small" color="secondary" @click="openPopover">
                <ion-icon name="more" color="moto" />
              </ion-button>
            </div>
          </div>


          <ion-segment class="top mt-10" scrollable @ionChange="tab = $event.target.value">
            <ion-segment-button value="INFO" :checked="tab == 'INFO'">
              <ion-label>{{ $t('info') }}</ion-label>
            </ion-segment-button>

            <ion-segment-button v-if="user && $gate.can('view', 'training_event_registrations', event)" value="REGISTRATIONS" :checked="tab == 'REGISTRATIONS'">
              <ion-label>{{ $t('registrations') }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>
      </PageItem>

      <div v-if="tab == 'INFO'">
        <PageItem v-if="competitionEvents.length">
          <ion-text color="moto">
            <h3>{{ $t('eventsincompetition') }}</h3>
          </ion-text>

          <ion-segment scrollable class="scrollable">
            <div class="scrollable-item" v-for="event in competitionEvents" :key="event.id">
              <EventItem button :event="event" :show-facility="true" @click.native="openEvent(event.id)" />
            </div>
          </ion-segment>
        </PageItem>

        <PageItem v-if="event.text">
          <ion-text color="moto">
            <h3>{{ $t('description') }}</h3>
          </ion-text>

          <div class="whitespace-pre-wrap">
            {{ event.text }}
          </div>
        </PageItem>

        <PageItem v-if="event.event_category.name == 'TRAINING'" @click.native="openTicketModal">
          <ion-text color="moto">
            <h3>{{ $t('trainingtickets') }}</h3>
          </ion-text>

          <div v-if="event.tickets.length">
            <div v-for="ticket in event.tickets" :key="ticket.id" class="flex justify-between">
              <span>{{ ticket.name }}</span><span>{{ ticket.price | priceAmount }}</span>
            </div>
          </div>

          <div v-else>
            {{ $t('noticketsavailable') }}
          </div>
        </PageItem>

        <PageItem v-if="availableProducts.length" @click.native="openTicketModal">
          <ion-text color="moto">
            <h3>{{ $t('productsforsale') }}</h3>
          </ion-text>

          <div>
            <div v-for="productForSale in availableProducts" :key="productForSale.id" class="flex justify-between">
              <span>{{ productForSale.productVariant.name }}</span><span>{{ productForSale.productVariant.price | priceAmount }}</span>
            </div>
          </div>
        </PageItem>
      </div>

      <EventRegistrations v-if="tab == 'REGISTRATIONS'" :event="event" />
    </ContentContainer>

    <ion-footer v-if="exportUrl && userIsAdmin">
      <ion-toolbar>
        <ion-button color="moto" expand="full" :href="exportUrl">
          {{ $t('downloadexport') }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<style scoped>

  ion-segment.scrollable {
    justify-content: start;
  }

  ion-segment .scrollable-item {
    display: flex;
    min-width: 280px;
  }

  ion-segment .scrollable-item ion-item {
    display: flex;
    flex: 1;
  }
</style>
<script>
  import PageItem from '@/components/PageItem'
  import TrainingTicketModal from '@/components/modals/TrainingTicketModal';
  import { GET_COMPETITION_EVENTS, GET_EVENT } from '@/graphql/queries'
  import EventRegistrations from "@/components/EventRegistrations";
  import EditEventPopOver from '@/components/popovers/EditEventPopOver.vue';
  import {
    eventSoldOut,
    noTicketsAvailable,
    eventIsOpen,
    eventHasPassed,
    eventIsCancelled,
    noProductsAvailable
  } from '@/utils/events';
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { mapGetters } from "vuex";
  import {AutoRefreshTimer, HumanDiff} from '@/mixins'
  import { STRIPE_CLIENT_ID } from "@/configs";
  import BuyCompetitionTicketModal from "@/components/modals/BuyCompetitionTicketModal";
  import EventItem from "@/components/items/EventItem";
  import { cloneDeep } from "lodash";

  export default {
    mixins: [AutoRefreshTimer, HumanDiff],
    components: {
      EventItem,
      EventRegistrations,
      PageItem
    },
    data() {
      return {
        useCompetitionEventsCache: true,
        STRIPE_CLIENT_ID,
        tab: 'INFO',
        currentUrl: window.location.href,
        popover: null,
        exportUrl: null,
        competitionEvents: [],
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
      availableProducts() {
        if (!this.event?.products_for_sale) {
          return [];
        }
        return this.event.products_for_sale.filter( (productForSale) => {
          return !productForSale.productVariant?.deleted_at;
        });
      },
      buttonType() {
        if (this.autoRefreshState) {
          // This is used to update the buttonType
        }

        if(!this.event.tickets.length) {
          return 'pay-cash';
        }

        if(eventIsCancelled(this.event)) {
          return 'cancelled';
        }

        if(eventHasPassed(this.event)) {
          return 'passed-event';
        }

        if(eventSoldOut(this.event)) {
          return 'sold-out';
        }

        if(noTicketsAvailable(this.event) && noProductsAvailable(this.event)) {
          return 'sold-out';
        }

        if(!eventIsOpen(this.event)) {
          return 'sale-not-started';
        }
        if (!this.event.organizer.stripe) {
          return 'payment-not-available';
        }

        return 'buy';
      },
      userIsAdmin() {
        return this.$gate.can('edit', 'events', this.event);
      },
      checkInIds() {

        if (this.user) {
          return this.user.user_checkins.map(user_checkin => user_checkin.id);
        }

        return [];
      },
    },
    mounted() {
      this.$on('exportReady', (value) => {
        this.popover.dismiss();

        this.exportUrl = value
      });

      this.$on('eventChanged', () => {
        this.$apollo.queries.event.refetch();
      });
    },
    apollo: {
      event: {
        query: GET_EVENT,
        variables() {
          return {
            id: this.$route.params.id,
          }
        },
        update(data) {
          if (data.event?.event_category?.name === 'COMPETITION') {
            this.loadCompetitionEvents(data.event.id, this.useCompetitionEventsCache);
          } else {
            this.competitionEvents = [];
          }
          return data.event;
        }
      }
    },
    methods: {
      openEvent(event_id) {
        this.$router.push({name: this.$routeNames.EVENT, params: {id: event_id}});
      },
      async loadCompetitionEvents(competition_event_id, useCache = false) {
        const result = await this.$apollo.query({
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
          query: GET_COMPETITION_EVENTS,
          variables: {
            competition_event_id
          }
        });

        this.competitionEvents = [...result.data.getCompetitionEvents];
      },
      seriesParentId(event) {
        if (event.recurring_type === 'NEVER') {
          return null;
        }
        return event.parent_id ?? event.id;
      },
      async openPopover(event) {
        try {
           const popOver = await this.$ionic.popoverController.create({
            component: EditEventPopOver,
            componentProps: {
              propsData: {
                canCancelEvent: !eventIsCancelled(this.event) && !eventHasPassed(this.event),
                seriesParentId: this.seriesParentId(this.event),
                eventId: this.event.id,
              },
              parent: this,
            },
            translucent: false,
            event
          })

          popOver.present();
          this.popover = popOver;
        } catch(err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
      doRefresh(event) {
        this.useCompetitionEventsCache = false;
        this.$apollo.queries.event.refetch().then(() => {
          event.target.complete()
        })
      },
      async openTicketModal() {
        if ([
          'sold-out',
          'cancelled',
          'passed-event',
          'sale-not-started',
          'payment-not-available'
        ].includes(this.buttonType)) {
          return;
        }

        const response = await this.$asyncAuth();

        if (response) {
          if (this.event.event_category.name === 'COMPETITION') {
            await this.openCompetitionModal();
          } else {
            await this.openTrainingTicketModal();
          }
        }
      },
      async openTrainingTicketModal() {
        const form = cloneDeep(this.event.forms.slice().pop());

        if(typeof form.profile_fields === 'string') {
          form.profile_fields = JSON.parse(form.profile_fields);
        }

        let modalInstance = null;

        const modal = await this.$ionic.modalController
          .create({
            component: TrainingTicketModal,
            componentProps: {
              parent: this,
              propsData: {
                event: this.event,
                form,
                tickets: cloneDeep(this.event.tickets),
                closeMe: () => {
                  this.$ionic.modalController.dismiss()
                },
                setInstance(__vm) {
                  modalInstance = __vm;
                }
              },
            },
          });

        /**
         * @todo Remove this workaround after ionic/vue fixes this issue.
         *
         * #193-graphql-error-variable-eventid-of-required-type-id-was-not-provided
         * https://trello.com/c/oksa3csG
         *
         * Our dialog does not want to get destroyed, this is a workaround to kill the dialog after we are done with it.
         */
        modal.onDidDismiss().then( () => {
          modalInstance?.$destroy();
        });

        return modal.present();
      },
      async openCompetitionModal() {
        const form = cloneDeep(this.event.forms.slice().pop());

        if(typeof form.profile_fields === 'string') {
          form.profile_fields = JSON.parse(form.profile_fields);
        }

        let modalInstance = null;

        const modal = await this.$ionic.modalController
          .create({
            component: BuyCompetitionTicketModal,
            componentProps: {
              parent: this,
              propsData: {
                event: this.event,
                form,
                tickets: this.event.tickets,
                closeMe: () => {
                  this.$ionic.modalController.dismiss()
                },
                setInstance(__vm) {
                  modalInstance = __vm;
                }
              },
            },
          });

        /**
         * @todo Remove this workaround after ionic/vue fixes this issue.
         *
         * #193-graphql-error-variable-eventid-of-required-type-id-was-not-provided
         * https://trello.com/c/oksa3csG
         *
         * Our dialog does not want to get destroyed, this is a workaround to kill the dialog after we are done with it.
         */
        modal.onDidDismiss().then( () => {
          modalInstance?.$destroy();
        });

        return modal.present();
      }
    }
  }
</script>

<style scoped>
    ion-segment.top ion-segment-button {
        --border-radius: 0;
        --border-width: 3px;
        color: var(--ion-color-white);
        --border-color: var(--ion-color-moto);
        --background-color: var(--ion-color-tertiary);
        --border-style: solid;
        --padding-top: 10px;
        --padding-bottom: 10px;
    }

    ion-segment.top ion-segment-button.segment-button-checked {
        border-bottom: 2px solid var(--ion-color-moto);
    }
</style>
