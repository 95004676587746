<template>
  <base-modal :title="$t('tickets')" @close="close">
    <ion-content>
      <ion-list>
        <ion-list-header>
          {{ $t('registrationform') }}
        </ion-list-header>

        <div v-for="(form_field, key) in fields" :key="key">
          <div v-if="form.profile_fields[key] && form.profile_fields[key].toggled">
            <div v-for="(field, fieldKey) in form_field.fields" :key="fieldKey">
              <Gender v-if="field.type == 'gender'" :field="field" :model="user" @inputChanged="form_values[key] = $event.target.value" />

              <InputText v-if="field.type == 'text'" :field="field" :disabled="field.userKey == 'email' && true" :model="user" @inputChanged="form_values[key] = $event.target.value" />

              <Date v-if="field.type == 'date'" :field="field" :model="user" @inputChanged="dateChanged($event.target.value, key)" />

              <Nationalities v-if="field.type == 'nationalities'" :field="field" :model="user" @inputChanged="form_values[key] = $event.target.value" />

              <Bike v-if="field.type == 'bike'" :field="field" :model="user" @inputChanged="form_values[key] = $event" />

              <MultiSelect
                v-if="field.type == 'multi-select'"
                :field="field"
                :model="user"
                @inputChanged="form_values[key] = $event"
              />

              <Toggle v-if="field.type == 'toggle'" :field="field" :model="user" @inputChanged="form_values[key] = $event" />

              <Doctor v-if="field.type == 'doctor'" :field="field" :model="user" @nameChanged="createAndSetFormObjectValue($event.target.value, 'name', key)" @phoneChanged="createAndSetFormObjectValue($event.target.value, 'phone', key)" />

              <Emergency v-if="field.type == 'emergency'" :field="field" :model="user" @nameChanged="createAndSetFormObjectValue($event.target.value, 'name', key)" @phoneChanged="createAndSetFormObjectValue($event.target.value, 'phone', key)" />

              <BloodTypes v-if="field.type == 'bloodtype'" :field="field" :model="user" @inputChanged="form_values[key] = $event.target.value" />

              <TextArea v-if="field.type == 'textarea'" :field="field" :model="user" @inputChanged="form_values[key] = $event.target.value" />
            </div>
          </div>
        </div>
      </ion-list>

      <ion-list v-if="additionalFields.length">
        <ion-list-header>
          <ion-label>{{ $t('extrainformation') }}</ion-label>
        </ion-list-header>
        <template v-for="(additional_field, key) in additionalFields">
          <SimpleSelect
            :key="key"
            v-if="additional_field.type === 'SELECT'"
            :label="additional_field.label"
            :value="additional_field.value"
            :options="additional_field.options"
            :required="additional_field.required"
            @inputChanged="additionalFields[key].value = $event"
          />
          <SimpleMultiSelect
            :key="key"
            v-else-if="additional_field.type === 'MULTISELECT'"
            :label="additional_field.label"
            :value="additional_field.value"
            :options="additional_field.options"
            :required="additional_field.required"
            @inputChanged="additionalFields[key].value = $event"
          />
          <ion-item
            :key="key"
            v-else>
            <ion-label position="stacked">{{ additional_field.label }}</ion-label>
            <ion-input
              :key="key"
              :placeholder="$t('enteravalue')"
              type="text"
              :required="additional_field.required"
              :value="additional_field.value"
              @ionInput="additionalFields[key].value = $event.target.value"
            />
          </ion-item>
        </template>
      </ion-list>

      <ion-list class="background-white" data-cy="tickets">
        <ion-list-header>
          <ion-label>{{ $t('choosetrainingticket') }}</ion-label>
        </ion-list-header>
        <ion-item v-for="ticket in tickets" :key="ticket.id">
          <ion-label>
            <ion-text color="white">
              <p>{{ ticket.name }}</p>
            </ion-text>

            <ion-text color="white">
              <p>{{ ticket.price | priceAmount }}</p>
            </ion-text>

            <ion-badge v-if="ticket.is_for_members && !(quantityAvailable(ticket) === 0)" slot="end" color="warning" class="mr-1">
              {{ $t('onlymembers') }}
            </ion-badge>

            <template v-if="ticket.is_required">
              <ion-badge v-if="!userHasPaidTicketForEvent(ticket)" slot="end" color="warning" class="mr-1">
                {{ $t('ticketisrequiredonceforevent') }}
              </ion-badge>
              <ion-badge v-else slot="end" color="success" class="mr-1">
                {{ $t('paidstatus') }}
              </ion-badge>
            </template>

            <ion-badge v-if="!requirementsMet(ticket)" slot="end" color="warning" class="mr-1">
              {{ $t('agerequirementsfailed') }}
            </ion-badge>

          </ion-label>

          <ion-badge v-if="quantityAvailable(ticket) === 0" slot="end" color="medium" class="mr-1">
            {{ $t('soldoutstatus') }}
          </ion-badge>

          <template v-else>
            <ion-checkbox
              v-if="ticket.max_tickets_per_ticket === 1"
              slot="end"
              color="moto"
              :checked="!!ticketQuantity(ticket)"
              :disabled="ticketDisabled(ticket)"
              @ionChange="toggleTicket(ticket, $event)"
            />

            <ion-select @ionChange="changeTicketQuantity(ticket, $event.target.value)" v-else :disabled="ticketDisabled(ticket)" value="0">
              <ion-select-option value="0">0</ion-select-option>
              <template v-for="value in ticket.max_tickets_per_ticket">
                <ion-select-option :key="value" :value="value" v-if="quantityAvailable(ticket) >= value">{{ value }}</ion-select-option>
              </template>
            </ion-select>
          </template>
        </ion-item>
      </ion-list>

      <ion-list class="background-white" data-cy="products" v-if="hasProductsForSale">
        <ion-list-header>
          <ion-label>{{ $t('chooseproducts') }}</ion-label>
        </ion-list-header>
        <ion-item v-for="productForSale in productsForSale" :key="productForSale.id">
          <ion-label>
            <ion-text color="white">
              <p>{{ productForSale.productVariant.name }}</p>
            </ion-text>

            <ion-text color="white">
              <p>{{ productForSale.productVariant.price | priceAmount }}</p>
            </ion-text>

            <ion-badge v-if="productForSale.is_for_members && !(stockAvailable(productForSale) === 0)" slot="end" color="warning" class="mr-1">
              {{ $t('onlymembers') }}
            </ion-badge>

            <template v-if="productForSale.is_required">
              <ion-badge v-if="!userHasPaidProductForEvent(productForSale)" slot="end" color="warning" class="mr-1">
                {{ $t('productisrequiredonceforevent') }}
              </ion-badge>
              <ion-badge v-else slot="end" color="success" class="mr-1">
                {{ $t('paidstatus') }}
              </ion-badge>
            </template>

            <ion-badge v-if="!requirementsMet(productForSale)" slot="end" color="warning" class="mr-1">
              {{ $t('agerequirementsfailed') }}
            </ion-badge>

          </ion-label>

          <ion-badge v-if="stockAvailable(productForSale) === 0" slot="end" color="medium" class="mr-1">
            {{ $t('soldoutstatus') }}
          </ion-badge>

          <template v-else>
            <ion-checkbox
              v-if="productForSale.max_per_order === 1"
              slot="end"
              color="moto"
              :checked="!!productQuantity(productForSale)"
              :disabled="productDisabled(productForSale)"
              @ionChange="toggleProduct(productForSale, $event)"
            />

            <ion-select @ionChange="changeProductQuantity(productForSale, $event.target.value)" v-else :disabled="productDisabled(productForSale)" value="0">
              <ion-select-option value="0">0</ion-select-option>
              <template v-for="value in productForSale.max_per_order">
                <ion-select-option :key="value" :value="value" v-if="stockAvailable(productForSale) >= value">{{ value }}</ion-select-option>
              </template>
            </ion-select>
          </template>
        </ion-item>
      </ion-list>

      <ion-list lines="full" v-if="enableTermsMon">
        <ion-list-header>
          <ion-label>{{ $t('mondisclaimer') }}</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-label text-wrap v-html="$t('mondisclaimer_html')" />
        </ion-item>

        <ion-item>
          <ion-checkbox slot="end" color="moto" :value="mon.checkboxRisk" :checked="mon.checkboxRisk" @ionChange="mon.checkboxRisk = !mon.checkboxRisk" />
          <ion-label text-wrap v-html="$t('mondisclaimeracknowledgement_html')" />
        </ion-item>

        <ion-item>
          <ion-checkbox slot="end" color="moto" :value="mon.checkboxMedical" :checked="mon.checkboxMedical" @ionChange="mon.checkboxMedical = !mon.checkboxMedical" />
          <ion-label text-wrap v-html="$t('medicalacknowledgement_html')" />
        </ion-item>

        <ion-item>
          <ion-checkbox slot="end" color="moto" :value="mon.checkboxPrivacy" :checked="mon.checkboxPrivacy" @ionChange="mon.checkboxPrivacy = !mon.checkboxPrivacy" />
          <ion-label text-wrap v-html="$t('privacyacknowledgement_html')" />
        </ion-item>

      </ion-list>

      <AdvertisementSlides ad-slot="checkout_button" margin-horizontal margin-bottom />

      <Error :error="error" />

      <ion-button
        color="moto"
        expand="full"
        :disabled="checkoutButtonDisabled"
        @click="checkoutWithStripe"
      ><ion-label>{{ $t('tocheckout') }}</ion-label></ion-button>

    </ion-content>
  </base-modal>
</template>

<script>
  import { CHECKOUT_EVENT_TICKETS, UPDATE_PAYMENT_METHOD_FOR_ORDER } from "@/graphql/mutations";
  import { get, find } from "lodash";
  import { fieldsFormPresentation } from "@/components/inputs/field_mapping";
  import Nationalities from "@/components/inputs/Nationalities";
  import Gender from "@/components/inputs/Gender";
  import InputText from "@/components/inputs/InputText";
  import Date from "@/components/inputs/Date";
  import MultiSelect from "@/components/inputs/MultiSelect";
  import Toggle from '@/components/inputs/Toggle';
  import Doctor from '@/components/inputs/Doctor';
  import Bike from '@/components/inputs/Bike';
  import Emergency from '@/components/inputs/Emergency';
  import BloodTypes from '@/components/inputs/BloodTypes';
  import TextArea from '@/components/inputs/TextArea'
  import * as moment from 'moment'
  import { createPivotable } from '../../utils/createPivotable';
  import { logoutAndRedirect } from '@/utils/logoutAndRedirect';
  import { hasAuthError } from '@/utils/hasAuthError';
  import BaseModal from './BaseModal.vue';
  import { Capacitor } from '@capacitor/core';
  import { Browser } from '@capacitor/browser';
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { priceAmount, stripeFee } from "@/utils";
  import { STRIPE_API_KEY } from "@/configs";
  import StripeCheckout from "@/components/modals/StripeCheckout";
  import { GET_ORDERS_FOR_EVENT } from "@/graphql/queries";
  import { REQUIREMENT_BETWEEN_AGE } from "@/graphql/enums";
  import { ValidationError } from "@/utils/validation";
  import { PRODUCT_FAMILIES } from "@/configs/productFamilies.config";
  import SimpleMultiSelect from "@/components/inputs/SimpleMultiSelect";
  import SimpleSelect from "@/components/inputs/SimpleSelect";
  import AdvertisementSlides from "../AdvertisementSlides";

  export default {
    components: {
      AdvertisementSlides,
      SimpleSelect,
      SimpleMultiSelect,
      BaseModal,
      BloodTypes,
      Bike,
      Date,
      Doctor,
      Emergency,
      Gender,
      InputText,
      MultiSelect,
      Nationalities,
      TextArea,
      Toggle,
    },
    props: ['tickets', 'closeMe', 'form', 'event', 'setInstance'],
    data() {
      return {
        ordersForEvent: [],
        updateTrigger: 0,
        fields: fieldsFormPresentation,
        newTickets: [],
        selectedProducts: [],
        ticketsWithCount: [],
        total: 0,
        mon: {
          checkboxRisk: false,
          checkboxMedical: false,
          checkboxPrivacy: false,
        },
        totalTickets: 0,
        form_values: {},
        additionalFields: {},
        extra_values: null,
        loading: false,
        error: null,
      }
    },
    computed: {
      ...mapGetters({ user: CURRENT_USER_GETTER }),
      productsForSale() {
        return this.event.products_for_sale.filter( (productForSale) => {
          return !productForSale.productVariant?.deleted_at;
        });
      },
      hasProductsForSale() {
        return !!this.event.products_for_sale.length;
      },
      subscriptionIds() {
        return this.user?.subscriptions?.map(subscription => subscription.id) ?? [];
      },
      termsFilledIn() {
        if(!this.enableTermsMon) {
          return true;
        }

        return this.mon.checkboxRisk && this.mon.checkboxMedical && this.mon.checkboxPrivacy;
      },
      checkoutButtonDisabled() {
        const requiredTicketsAreNotSelected = this.tickets.some( (ticket) => {
            return (ticket.is_required && !this.ticketQuantity(ticket) && !this.userHasPaidTicketForEvent(ticket));
        })

        const requiredProductsAreNotSelected = this.productsForSale.some( (productForSale) => {
            return (productForSale.is_required && !this.productQuantity(productForSale) && !this.userHasPaidProductForEvent(productForSale));
        })

        return (requiredTicketsAreNotSelected || requiredProductsAreNotSelected) || (this.totalPrice === undefined) || this.loading || this.ticketSoldOut || !this.termsFilledIn || (!this.newTickets.length && !this.selectedProducts.length)
      },
      enableTermsMon() {
        const displayMonTicketTerms = this.newTickets.some( (newTicket) => {
          const ticket = find(this.tickets, {id: newTicket.ticket_id});
          return (ticket?.name?.toLowerCase()?.includes('daglicentie'));
        });

        const displayMonProductTerms = this.selectedProducts.some( (selectedProduct) => {
          const product = find(this.productsForSale, {id: selectedProduct.product_for_sale_id});
          return (product?.productVariant?.product?.productFamily?.id === PRODUCT_FAMILIES.MON_WA);
        });

        return displayMonProductTerms || displayMonTicketTerms;
      },
      ticketSoldOut() {
        return this.newTickets.some( (newTicket) => {
            const ticket = find(this.tickets, {id: newTicket.ticket_id});
            return (this.quantityAvailable(ticket) < newTicket.quantity);
        });
      },
      productsSoldOut() {
        return this.selectedProducts.some( (selectedProduct) => {
            const product = find(this.productsForSale, {id: selectedProduct.product_for_sale_id});
            return (this.stockAvailable(product) < selectedProduct.quantity);
        });
      },
      totalPrice() {
        const ticketPrice = this.newTickets.reduce( (totalPrice, currentTicket) => {
          const ticket = find(this.tickets, {id: currentTicket.ticket_id});
          return totalPrice + ticket.price * currentTicket.quantity;
        }, 0)

        const productPrice = this.selectedProducts.reduce( (totalPrice, selectedProduct) => {
          const product = find(this.productsForSale, {id: selectedProduct.product_for_sale_id});
          return totalPrice + product.productVariant.price * selectedProduct.quantity;
        }, 0)

        return ticketPrice + productPrice;
      },
    },
    created() {
      /**
       * Inform our parent that we have been created, so it can destroy us.
       */
      if (this.setInstance) {
        this.setInstance(this);
      }

      this.tickets.forEach(ticket => {
        ticket.count = 0;
        this.ticketsWithCount.push(ticket);
      })

      this.hydrateFormValues();
      this.hydrateAdditionalFields();
      this.fetchOrdersForEvent();
    },
    watch: {
      updateTrigger() {
        /**
         *  Our disabled key relies on form_values, which add/removes properties on-the-fly, which is not supported by vue-watchers
         *  This is a cheap way to update the UI
         */
        this.$nextTick( () => {
          this.$forceUpdate();
        })
      }
    },
    methods: {
      priceAmount,
      stripeFee,
      async fetchOrdersForEvent() {
        const response = await this.$apollo.query({
          query: GET_ORDERS_FOR_EVENT,
          fetchPolicy: 'network-only',
          variables: {
            event_id: this.event.id,
          }
        });
        this.ordersForEvent = response.data.getOrdersForEvent;
      },
      userHasPaidTicketForEvent(ticket) {
        if (!this.ordersForEvent.length) {
          return false;
        }

        return this.ordersForEvent.some( (order) => {
          if (order.stripe_status !== 'paid') {
            return false;
          }
          return order.tickets.some( (paidTicket)  => {
            return parseInt(paidTicket.id) === parseInt(ticket.id);
          })
        })
      },
      lineItems() {
        return this.newTickets.concat(this.selectedProducts);
      },
      userHasPaidProductForEvent(productForSale) {
        if (!this.ordersForEvent.length) {
          return false;
        }

        return this.ordersForEvent.some( (order) => {
          if (order.stripe_status !== 'paid') {
            return false;
          }
          return order.products.some( (paidProduct)  => {
            return parseInt(paidProduct.productVariant.id) === parseInt(productForSale.product_variant_id);
          })
        })
      },
      async openStripeCheckoutModal() {
          const p = new Promise( async (resolve) => {
            const propsData = {
              closeMe: null,
              line_items: this.lineItems(),
              user: this.user,
              confirm: (paymentMethod) => {
                modal.dismiss();
                resolve(paymentMethod);
              }
            };

            const modal = await this.$ionic.modalController
              .create({
                component: StripeCheckout,
                componentProps: {
                  parent: this,
                  propsData
                },
              });

            propsData.closeMe = () => {
              modal.dismiss();
              resolve(false);
            };

            return modal.present();
          });
          return p;
      },
      ticketQuantity(ticket) {
        return this.newTickets.find(newTicket => newTicket.ticket_id === ticket.id)?.quantity;
      },
      toggleTicket(ticket, $event) {
        this.changeTicketQuantity(ticket, $event.detail.checked ? 1 : 0);
      },
      productQuantity(productForSale) {
        return this.selectedProducts.find(selectedProduct => selectedProduct.product_for_sale_id === productForSale.id)?.quantity;
      },
      toggleProduct(productForSale, $event) {
        this.changeProductQuantity(productForSale, $event.detail.checked ? 1 : 0);
      },
      requirementsMet(objectWithRequirements) {
        if (!objectWithRequirements.requirements) {
          return true;
        }

        const failedRequirements = objectWithRequirements.requirements.some( (requirement) => {
          if (!this.form_values?.date_of_birth) {
            return true;
          }

          let ageAtTimeOfEvent;
          switch (requirement.type) {
            case REQUIREMENT_BETWEEN_AGE:
              // Age is the difference in dates between the start of the event and the birthdate of the person
              ageAtTimeOfEvent = moment(this.event.held_at).diff(this.form_values['date_of_birth'], 'years');

              // Fail minimum age
              if (requirement.params?.min && (requirement.params?.min > ageAtTimeOfEvent)) {
                return true;
              }
              // Fail maximum age
              if (requirement.params?.max && (requirement.params?.max < ageAtTimeOfEvent)) {
                return true;
              }

              break;
          }

          return false;
        })

        return !failedRequirements;
      },
      changeTicketQuantity(ticket, quantity) {
        const parsedQuantity = parseInt(quantity);

        this.newTickets = this.newTickets.filter(newTicket => newTicket.ticket_id !== ticket.id);

        if (parsedQuantity) {
          this.newTickets.push({
            ticket_id: ticket.id,
            name: ticket.name,
            price: ticket.price,
            quantity: parsedQuantity,
          });
        }

      },
      changeProductQuantity(productForSale, quantity) {
        const parsedQuantity = parseInt(quantity);

        this.selectedProducts = this.selectedProducts.filter(selectedProduct => selectedProduct.product_for_sale_id !== productForSale.id);

        if (parsedQuantity) {
          this.selectedProducts.push({
            product_for_sale_id: productForSale.id,
            name: productForSale.productVariant.name,
            price: productForSale.productVariant.price,
            quantity: parsedQuantity,
          });
        }

      },
      quantityAvailable(ticket) {
         if (ticket.quantity_available === 0) {
           return Number.MAX_SAFE_INTEGER;
         }

         if (ticket.sold_tickets_count >= ticket.quantity_available) {
           return 0;
         }

        return ticket.quantity_available - ticket.sold_tickets_count;
      },
      stockAvailable(productForSale) {
         if (productForSale.quantity_available === 0) {
           return Number.MAX_SAFE_INTEGER;
         }

         if (productForSale.sold_orders_count >= productForSale.quantity_available) {
           return 0;
         }

        return productForSale.quantity_available - productForSale.sold_orders_count;
      },
      ticketDisabled(ticket) {

        if (!this.requirementsMet(ticket)) {
          return true;
        }

        if (ticket.is_required && (ticket.max_tickets_per_ticket === 1) && this.userHasPaidTicketForEvent(ticket)) {
          return true;
        }

        if(!ticket.is_for_members) {
          return false;
        }

        if(!ticket.subscriptions.length) {
          return false;
        }

        if(!this.subscriptionIds.length && ticket.subscriptions.length) {
          return true;
        }

        return !ticket.subscriptions.some(subscription => this.subscriptionIds.includes(subscription.id));
      },
      productDisabled(productForSale) {

        if (!this.requirementsMet(productForSale)) {
          return true;
        }

        // product is required, and user has paid for the product already and only one product can be bought
        if (productForSale.is_required && (productForSale.max_per_order === 1) && this.userHasPaidProductForEvent(productForSale)) {
          return true;
        }

        if(!productForSale.is_for_members) {
          return false;
        }

        if(!productForSale.subscriptions.length) {
          return false;
        }

        if(!this.subscriptionIds.length && productForSale.subscriptions.length) {
          return true;
        }

        return !productForSale.subscriptions.some(subscription => this.subscriptionIds.includes(subscription.id));
      },
      dateChanged(value, key) {
        this.form_values[key] = moment(value).format('YYYY-MM-DD');
        this.updateTrigger++;
      },
      hydrateAdditionalFields() {
        const additionalFields = JSON.parse(this.form.additional_fields);

        this.additionalFields = additionalFields.map(additionalField => {
          return {
            ...additionalField,
            value: this.emptyValueForAdditionalField(additionalField),
          }
        });
      },
      emptyValueForAdditionalField(additionalField) {
        if (additionalField.type === 'MULTISELECT') {
          return []
        }
        return ''
      },
      hydrateFormValues() {
        var fieldsFormPresentationArray = Object.entries(fieldsFormPresentation);

        fieldsFormPresentationArray.forEach((form_field) => {
          if(this.form.profile_fields[form_field[0]] && this.form.profile_fields[form_field[0]].toggled) {
            form_field[1].fields.forEach(field => {
              switch(field.type) {
                case 'nationalities':
                  this.form_values[form_field[0]] = this.user[field.userKey]?.id
                  break;
                case 'bloodtype':
                  this.form_values[form_field[0]] = this.user[field.userKey]?.id
                  break;
                case 'bike':
                  if(this.user[field.userKey] && this.user[field.userKey].length) {
                    const mappedValues = this.user[field.userKey].map(createPivotable)

                    this.form_values[form_field[0]] = mappedValues
                    break;
                  }

                  this.form_values[form_field[0]] = null;
                  break;
                case 'doctor':
                  this.form_values[form_field[0]] = {
                    name: this.user.doctor_name,
                    phone: this.user.doctor_tel
                  }
                  break;
                case 'emergency':
                  this.form_values[form_field[0]] = {
                    name: this.user.contact_person_name,
                    phone: this.user.contact_person_tel
                  }
                  break;
                case 'multi-select':
                  if(this.user[field.userKey] && this.user[field.userKey].length) {
                    const mappedValues = this.user[field.userKey].map(createPivotable)

                    this.form_values[form_field[0]] = mappedValues
                    break;
                  }

                  this.form_values[form_field[0]] = null;
                  break;
                default:
                  this.form_values[form_field[0]] = this.user[field.userKey]
              }
            })
          }
        })
      },
      createAndSetFormObjectValue(value, valueKey, fieldKey) {
        if (typeof this.form_values[fieldKey] != 'object') {
          this.form_values[fieldKey] = {};
          this.form_values[fieldKey][valueKey] = value
        } else {
          this.form_values[fieldKey][valueKey] = value;
        }
      },
      createOrder() {
        return this.$apollo.mutate({
          mutation: CHECKOUT_EVENT_TICKETS,
          variables: {
            event: this.event.id,
            payment_method: "ideal",
            tickets: this.newTickets.map( ({ticket_id, quantity}) => {
              return { ticket_id, quantity }
            } ),
            products: this.selectedProducts.map( ({product_for_sale_id, quantity}) => {
              return { product_for_sale_id, quantity }
            } ),
            additional_values: this.additionalFields,
            form_submission: {
              ...this.form_values,
              which_bike: JSON.stringify(this.form_values.which_bike),
              which_federation: JSON.stringify(this.form_values.which_federation),
              which_sport: JSON.stringify(this.form_values.which_sport),
            }
          },
        })
      },
      updatePaymentMethodForOrder(order_id, payment_method) {
        return this.$apollo.mutate({
          mutation: UPDATE_PAYMENT_METHOD_FOR_ORDER,
          variables: {
            id: order_id,
            payment_method,
          },
        }).then( (result) => {
          return result.data.updatePaymentMethodForOrder
        } );
      },
      async checkoutWithStripe() {
        this.loading = true;
        this.error = null;

        // Premature return
        this.additionalFields.map(additionalField => {
          if((!additionalField.value || !additionalField.value.length) && additionalField.required) {
            if (!this.error) {
              this.error = new ValidationError();
            }

            this.error.add(additionalField.label, this.$t('validation_required', {attribute: additionalField.label}));
          }
        });

        // Additional-fields have errors
        if (this.error) {
          this.loading = false;
          return;
        }

        try {
          const data = await this.createOrder();

          if(get(data, 'errors[0].extensions.category') === 'authentication') {
            this.close()

            await logoutAndRedirect();
            return;
          }

          let result = data.data.buyEventTickets;

          if (result.__typename === 'Order') {
            this.close();

            await this.$router.push({name: this.$routeNames.THANKS});
            return;
          }

          if(result.stripe_account) {

            // In our new api, we will update the order and not the session_id
            if (!result.session_id) {
              const paymentMethod = await this.openStripeCheckoutModal();

              if (!paymentMethod) {
                return;
              } else {
                result = await this.updatePaymentMethodForOrder(result.order_id, paymentMethod);
              }
            }

            return this.openStripeBrowserSession(result.session_id);
          }
        } catch( error ) {

          if(hasAuthError(error)) {
            this.close();
            await logoutAndRedirect();
          }

          this.error = error;
        } finally {
          this.loading = false;
        }

      },
      async openStripeBrowserSession(sessionId) {

        if(Capacitor.isNativePlatform()) {
          await Browser.open({ url: `${process.env.VUE_APP_API_URL}/redirect-to-stripe?session_id=${sessionId}` });
          return;
        }

        const stripe = window.Stripe(STRIPE_API_KEY);

        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.

          // eslint-disable-next-line no-console
          console.log(result.error.message)
        });
      },
      close() {
        this.closeMe();
      }
    }

  }
</script>
