<template>

  <ion-list lines="full">
    <ion-list-header>
      <ion-label>{{ $t('mondisclaimer') }}</ion-label>
    </ion-list-header>

    <ion-item>
      <ion-label text-wrap v-html="$t('mondisclaimer_html')" />
    </ion-item>

    <ion-item>
      <ion-checkbox
        slot="end"
        color="moto"
        :value="termsMon.checkboxRisk"
        :checked="termsMon.checkboxRisk"
        @ionChange="toggleTermsMon({key: 'checkboxRisk', checked: $event.detail.checked})"
      />
      <ion-label text-wrap v-html="$t('mondisclaimeracknowledgement_html')" />
    </ion-item>

    <ion-item>
      <ion-checkbox
        slot="end"
        color="moto"
        :value="termsMon.checkboxMedical"
        :checked="termsMon.checkboxMedical"
        @ionChange="toggleTermsMon({key: 'checkboxMedical', checked: $event.detail.checked})"
      />
      <ion-label text-wrap v-html="$t('medicalacknowledgement_html')" />
    </ion-item>

    <ion-item>
      <ion-checkbox
        slot="end"
        color="moto"
        :value="termsMon.checkboxPrivacy"
        :checked="termsMon.checkboxPrivacy"
        @ionChange="toggleTermsMon({key: 'checkboxPrivacy', checked: $event.detail.checked})"
      />
      <ion-label text-wrap v-html="$t('privacyacknowledgement_html')" />
    </ion-item>

  </ion-list>

</template>

<script>
  import { mapGetters, mapMutations } from "vuex";

  export default {
    name: 'TermsMon',
    computed: {
      ...mapGetters([
        'termsMon',
      ])
    },
    methods: {
      ...mapMutations([
        'toggleTermsMon'
      ])
    }
  }
</script>