<template>
  <base-modal :title="$t('detailsofregistration')" @close="close">
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label color="moto"><span class="text-sm">{{ $t('registeredat') }}</span></ion-label>
          <ion-text color="moto">{{ orderDate | formatDateTime }}</ion-text>
        </ion-item>
        <ion-item v-for="(submitted_value, key) in parsedValues" :key="key">
          <ion-label><span class="text-sm">{{ $t(submitted_value.label) }}</span></ion-label>
          <div class="text-sm">
            <template v-if="submitted_value.key === 'which_bike'">
              <div v-for="bike in submitted_value.value" :key="bike.id">
                <template v-if="bike.pivot.is_default">
                  <span v-if="bike.bike_make">
                    {{ bike.bike_make.make }} {{ bike.model }} {{ bike.build_year }}
                  </span>
                  <span v-else>
                    {{ bike.name }}
                  </span>
                </template>
              </div>
            </template>
            <template v-else-if="submitted_value.key === 'which_federation'">
              <div v-for="federation in submitted_value.value" :key="federation.id">
                <template v-if="federation.pivot.is_default">
                  {{ federation.name }}
                </template>
              </div>
            </template>
            <template v-else-if="submitted_value.key === 'which_sport'">
              <div v-for="sport in submitted_value.value" :key="sport.id">
                <template v-if="sport.pivot.is_default">
                  {{ sport.name }}
                </template>
              </div>
            </template>
            <template v-else-if="submitted_value.key === 'nationality'">
              <div>
                {{ countryName(submitted_value.value) }}
              </div>
            </template>
            <div v-else-if="submitted_value.key === 'has_ehbo'">
              <div v-if="submitted_value.value == true">
                {{ $t('yes') }}
              </div>
              <div v-else>
                {{ $t('no') }}
              </div>
            </div>
            <div v-else-if="submitted_value.key === 'gender'">
              <div v-if="submitted_value.value == 'MALE'">
                {{ $t('male') }}
              </div>
              <div v-else>
                {{ $t('female') }}
              </div>
            </div>
            <div v-else-if="submitted_value.key === 'doctor'">
              <div>{{ submitted_value.value.name }}</div>
              <div>{{ submitted_value.value.phone }}</div>
            </div>
            <div v-else-if="submitted_value.key === 'emergency'">
              <div>{{ submitted_value.value.name }}</div>
              <div>{{ submitted_value.value.phone }}</div>
            </div>

            <template v-else>
              {{ submitted_value.value }}
            </template>
          </div>
        </ion-item>
      </ion-list>

      <ion-list v-if="additionalValuesParsed && additionalValuesParsed.length">
        <ion-list-header>
          <ion-label>{{ $t('extrainformation') }}</ion-label>
        </ion-list-header>

        <ion-item v-for="(additional_value, key) in additionalValuesParsed" :key="key">
          <ion-label><span class="text-sm">{{ additional_value.label }}</span></ion-label>

          <div class="text-sm">
            {{ renderAdditionalValue(additional_value.value) }}
          </div>
        </ion-item>
      </ion-list>

      <ion-list v-if="eventGroups.length">
        <template v-for="(eventGroup, idx) in eventGroups">
          <div :key="idx">
            <ion-item-divider color="dark">
              <ion-text color="moto">{{ $t('event') }}: {{ eventGroup.event.title }}</ion-text>
              <ion-text slot="end" class="mr-4">{{ eventGroup.event.held_at | formatDateTime }}</ion-text>
            </ion-item-divider>

            <ion-item-divider v-show="Object.keys(eventGroup.groupedTickets).length" color="tertiary">
              <ion-label color="moto">
                <span class="text-sm">{{ $t('tickets') }}</span>
              </ion-label>
            </ion-item-divider>
            <ion-item v-for="(group, idx) in eventGroup.groupedTickets" :key="idx">
              <ion-label :color="group.is_refunded ? 'danger' : null">{{ group.items.length }}x {{ group.name }}</ion-label>
              <ion-badge :color="group.is_refunded ? 'danger' : 'moto'" slot="end" class="text-right">{{ group.items[0].pivot.price_at_checkout | priceAmount }}</ion-badge>
            </ion-item>

            <ion-item-divider v-show="Object.keys(eventGroup.groupedProducts).length">
              <ion-label color="moto">{{ $t('products') }}</ion-label>
            </ion-item-divider>
            <ion-item v-for="(group, idx) in eventGroup.groupedProducts" :key="idx">
              <ion-label :color="group.is_refunded ? 'danger' : null">{{ group.items.length }}x {{ group.name }}</ion-label>
              <ion-badge :color="group.is_refunded ? 'danger' : 'moto'" slot="end" class="text-right">{{ group.items[0].pivot.price_at_checkout | priceAmount }}</ion-badge>
            </ion-item>
          </div>
        </template>
      </ion-list>
    </ion-content>
  </base-modal>
</template>

<script>

    import { fieldsFormPresentation } from '@/components/inputs/field_mapping'
    import moment from 'moment-timezone';
    import BaseModal from './BaseModal.vue';
    import { GET_NATIONALITIES } from "@/graphql/queries";
    import { groupOrderablesByEvent } from "@/utils";

    export default {
      props: ['attendee', 'submittedValues', 'additionalValues'],
      components: {
        BaseModal
      },
      computed: {
        orderDate() {
          if (!this.attendee?.order) {
            return null;
          }
          return moment.utc(this.attendee.order.created_at).tz('Europe/Amsterdam')
        },
        additionalValuesParsed() {
          return JSON.parse(this.additionalValues);
        },
        parsedValues() {
          let parsedSubmitted = Object.entries(JSON.parse(this.submittedValues));
          let fieldFormPresentationParsed = Object.entries(fieldsFormPresentation);

          let labelPlusValues = []

          fieldFormPresentationParsed.forEach(fieldFormPresentation => {
            parsedSubmitted.forEach(parsedSubmit => {

                if(parsedSubmit[0] == fieldFormPresentation[0]) {
                  labelPlusValues.push({
                    key: fieldFormPresentation[0],
                    type: fieldFormPresentation[1].fields[0].type,
                    label: fieldFormPresentation[1].fields[0].label,
                    value: this.formatValue(parsedSubmit[1], fieldFormPresentation[1].fields[0].type, fieldFormPresentation[0])
                  })
                }
              })
            })

          return labelPlusValues;
        },
        eventGroups() {
          return groupOrderablesByEvent([this.attendee.order]);
        }
      },
      methods: {
        renderAdditionalValue(value) {
          if (Array.isArray(value)) {
            return value.join(', ');
          }

          return value;
        },
        countryName(id) {
          if (!this.nationalities || !id) {
            return null;
          }

          const nationality = this.nationalities.find((nationality) => parseInt(nationality.id) === parseInt(id));

          return nationality?.country;
        },

        close() {
          this.$ionic.modalController.dismiss();
        },

        formatValue(value, type, key) {
          // TODO: Crappy backward compatible support for this value
          if (key === 'which_sport') {
            // ['[{"id....}]'] with the old database values:          An array with a single string key
            // [ { id: ... }, { ...} ] with the new database values : An array of objects

            return (typeof value[0] === 'string') ? JSON.parse(value) : value
          }

          switch(type) {
            case 'text':
              return value;
            case 'date':
              return moment(value).format('DD-MM-YYYY')
            default:
              return value
          }
        }
      },
      apollo: {
        nationalities: GET_NATIONALITIES
      },
    }
</script>
