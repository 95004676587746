<template>
  <div v-if="form && user">
    <ion-list>
      <ion-list-header>
        <ion-text color="moto">{{ $t('registrationform') }}</ion-text>
      </ion-list-header>

      <div v-for="(form_field, key) in fields" :key="key">
        <div v-if="form.profile_fields[key] && form.profile_fields[key].toggled">
          <div v-for="(field, fieldKey) in form_field.fields" :key="fieldKey">
            <Gender v-if="field.type == 'gender'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event.target.value)" />

            <InputText v-if="field.type == 'text'" :field="field" :disabled="field.userKey == 'email' && true" :model="user" @inputChanged="changeFormValue(key, $event.target.value)" />

            <Date v-if="field.type == 'date'" :field="field" :model="user" @inputChanged="dateChanged($event.target.value, key)" />

            <Nationalities v-if="field.type == 'nationalities'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event.target.value)" />

            <Bike v-if="field.type == 'bike'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event)" />

            <MultiSelect
              v-if="field.type == 'multi-select'"
              :field="field"
              :model="user"
              @inputChanged="changeFormValue(key, $event)"
            />

            <Toggle v-if="field.type == 'toggle'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event)" />

            <Doctor
              v-if="field.type == 'doctor'"
              :field="field"
              :model="user"
              @nameChanged="createAndSetFormObjectValue(key, 'name', $event.target.value)"
              @phoneChanged="createAndSetFormObjectValue(key, 'phone', $event.target.value)"
            />

            <Emergency
              v-if="field.type == 'emergency'"
              :field="field"
              :model="user"
              @nameChanged="createAndSetFormObjectValue(key, 'name', $event.target.value)"
              @phoneChanged="createAndSetFormObjectValue(key , 'phone', $event.target.value)"
            />

            <BloodTypes v-if="field.type == 'bloodtype'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event.target.value)" />

            <TextArea v-if="field.type == 'textarea'" :field="field" :model="user" @inputChanged="changeFormValue(key, $event.target.value)" />
          </div>
        </div>
      </div>
    </ion-list>

    <ion-list v-if="additionalFields && additionalFields.length">
      <ion-list-header>
        <ion-label>{{ $t('extrainformation') }}</ion-label>
      </ion-list-header>
      <template v-for="(additional_field, key) in additionalFields">
        <SimpleSelect
          :key="key"
          v-if="additional_field.type === 'SELECT'"
          :label="additional_field.label"
          :value="additional_field.value"
          :options="additional_field.options"
          :required="additional_field.required"
          @inputChanged="changeAdditionalFieldValue(key, $event)"
        />
        <SimpleMultiSelect
          :key="key"
          v-else-if="additional_field.type === 'MULTISELECT'"
          :label="additional_field.label"
          :value="additional_field.value"
          :options="additional_field.options"
          :required="additional_field.required"
          @inputChanged="changeAdditionalFieldValue(key, $event)"
        />
        <ion-item
          :key="key"
          v-else>
          <ion-label position="stacked">{{ additional_field.label }}</ion-label>
          <ion-input
            :key="key"
            :placeholder="$t('enteravalue')"
            type="text"
            :required="additional_field.required"
            :value="additional_field.value"
            @ionInput="changeAdditionalFieldValue(key, $event.target.value)"
          />
        </ion-item>
      </template>
    </ion-list>
  </div>
</template>

<script>
  import * as moment from "moment";
  import BloodTypes from "@/components/inputs/BloodTypes";
  import Bike from "@/components/inputs/Bike";
  import Date from "@/components/inputs/Date";
  import Doctor from "@/components/inputs/Doctor";
  import Emergency from "@/components/inputs/Emergency";
  import Gender from "@/components/inputs/Gender";
  import InputText from "@/components/inputs/InputText";
  import MultiSelect from "@/components/inputs/MultiSelect";
  import Nationalities from "@/components/inputs/Nationalities";
  import TextArea from "@/components/inputs/TextArea";
  import Toggle from "@/components/inputs/Toggle";
  import { mapGetters, mapMutations } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import SimpleSelect from "@/components/inputs/SimpleSelect";
  import SimpleMultiSelect from "@/components/inputs/SimpleMultiSelect";

  export default {
    name: 'RegistrationForm',
    components: {
      SimpleMultiSelect,
      SimpleSelect,
      BloodTypes,
      Bike,
      Date,
      Doctor,
      Emergency,
      Gender,
      InputText,
      MultiSelect,
      Nationalities,
      TextArea,
      Toggle,
    },
    computed: {
      ...mapGetters({
        form: 'getRegistrationForm',
        fields: 'getRegistrationPresentation',
        user: CURRENT_USER_GETTER,
        form_values: 'getRegistrationFormValues',
        additionalFields: 'getRegistrationAdditionalFields'
      }),
    },
    methods: {
      ...mapMutations([
        'setRegistrationFormValue',
        'setRegistrationAdditionalFieldValue',
        'setRegistrationFormObjectValue'
      ]),
      changeFormValue(key, value) {
        this.setRegistrationFormValue({key, value})
      },
      changeAdditionalFieldValue(key, value) {
        this.setRegistrationAdditionalFieldValue({key, value});
      },
      createAndSetFormObjectValue(key, valueKey, value) {
        this.setRegistrationFormObjectValue({key, valueKey, value});
      },
      dateChanged(value, key) {
        this.setRegistrationFormValue(key, moment(value).format('YYYY-MM-DD'));
      },
    }
  }
</script>