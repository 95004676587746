<template>
  <ion-list class="background-white" data-cy="products">
    <ion-list-header>
      <ion-label color="moto">{{ $t('chooseproducts') }}</ion-label>
    </ion-list-header>
    <ion-item v-for="productForSale in productsForSale" :key="productForSale.id">
      <ion-label>
        <ion-text color="white">
          <p>{{ productForSale.productVariant.name }}</p>
        </ion-text>

        <ion-text color="white">
          <p>{{ productForSale.productVariant.price | priceAmount }}</p>
        </ion-text>

        <ion-badge v-if="productForSale.is_for_members && !(stockAvailable(productForSale) === 0)" slot="end" color="warning" class="mr-1">
          {{ $t('onlymembers') }}
        </ion-badge>

        <template v-if="productForSale.is_required">
          <ion-badge v-if="!userHasPaidProductForEvent(productForSale)" slot="end" color="warning" class="mr-1">
            {{ $t('productisrequiredonceforevent') }}
          </ion-badge>
          <ion-badge v-else slot="end" color="success" class="mr-1">
            {{ $t('paidstatus') }}
          </ion-badge>
        </template>

        <ion-badge v-if="!requirementsMet(productForSale)" slot="end" color="warning" class="mr-1">
          {{ $t('agerequirementsfailed') }}
        </ion-badge>

      </ion-label>

      <ion-badge v-if="stockAvailable(productForSale) === 0" slot="end" color="medium" class="mr-1">
        {{ $t('soldoutstatus') }}
      </ion-badge>

      <template v-else>
        <ion-checkbox
          v-if="productForSale.max_per_order === 1"
          slot="end"
          color="moto"
          :checked="!!getSelectedProductQuantity(productForSale)"
          :disabled="productForSaleIsDisabled(productForSale)"
          @ionChange="toggleSelectedProduct({product:productForSale, checked: $event.detail.checked})"
        />

        <ion-select
          v-else
          value="0"
          :disabled="productForSaleIsDisabled(productForSale)"
          @ionChange="changeSelectedProductQuantity({product: productForSale, quantity: $event.target.value})"
        >
          <ion-select-option value="0">0</ion-select-option>
          <template v-for="value in productForSale.max_per_order">
            <ion-select-option :key="value" :value="value" v-if="stockAvailable(productForSale) >= value">{{ value }}</ion-select-option>
          </template>
        </ion-select>
      </template>
    </ion-item>
  </ion-list>
</template>

<script>
  import { stockAvailable } from "@/utils";
  import { mapActions, mapGetters, mapMutations } from "vuex";

  export default {
    name: 'ChooseProductsToBuy',

    props: {
      productsForSale: {}
    },
    computed: {
      ...mapGetters([
        'requirementsMet',
        'getSelectedProductQuantity',
        'userHasPaidProductForEvent',
        'productForSaleIsDisabled',
      ])
    },
    methods: {
      stockAvailable,
      ...mapMutations([
        'changeSelectedProductQuantity'
      ]),
      ...mapActions([
        'toggleSelectedProduct',
      ]),
    }
  }
</script>